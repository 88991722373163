.cardsRow
  align-items: stretch
  display: flex
  flex-direction: column
  justify-content: flex-start

  :global(.card)
    margin-bottom: 16px

    &:last
      margin-bottom: 0

@media all and (min-width: 768px)
  .cardsRow
    flex-direction: row
    justify-content: space-between

    :global(.card)
      flex: 1
      margin-right: 16px

      &:last-child
        margin-right: 0

@media all and (min-width: 992px)
  .cardsRow
    :global(.card)
      margin-right: 24px

      &:last-child
        margin-right: 0
