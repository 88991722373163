@import styles/_variables

.sectionTop
  background-color: #feffff
  background-image: url('~assets/images/footer.png')
  background-position: 50% 50%
  background-repeat: no-repeat
  background-size: cover

.sectionBottom
  background-color: $fc-color-dark-brown

  &,
  a
    color: #fff
    font-size: .75rem
    padding: 1em 0
    text-decoration: none

  :global(.col + .col)
    text-align: right

.colNavigation

  h3
    align-items: center
    display: flex
    font-size: 1.125em
    font-weight: 400
    // justify-content: center
    letter-spacing: .05em
    margin-bottom: .25em
    text-align: center
    text-transform: uppercase
    width: 100%

    svg
      color: #d1d5d8
      font-size: .75em
      margin-right: .5em

  :global(.headline-container)
    &:after
      background-color: #d8d9db
      content: ""
      display: block
      height: 2px
      // margin: 8px auto 0 auto
      margin-left: 1.25em
      margin-top: 8px
      width: 54px

  ul
    list-style: none
    margin: .75em 0 1.25em 1.25em
    padding: 0

  a
    color: #333
    display: block
    padding: .5em 0
    text-align: left
    text-decoration: none

@media all and (min-width: 768px)
  .colNavigation
    a
      font-size: .875em
      padding: .25em 0


@media all and (min-width: 992px)
  .colNavigation
    a
      font-size: .825em
      padding: .1em 0

.externalSocialMedia
  align-items: center
  border-top: 1px solid rgba(#000, .25)
  display: flex
  justify-content: center
  margin-top: 1.5em

  a
    display: block
    font-size: 3em
    padding: .25em .5em

    &:global(.instagram)
      color: #CF2872

    &:global(.facebook)
      color: #3b579d
      font-size: 3.3em

    &:global(.xing)
      color: #025a61
      font-size: 2.85em

@media all and (min-width: 768px)
  .externalSocialMedia
    border-top: none
    justify-content: flex-start
    margin-top: .5em

    a
      color: $fc-color-dark-brown
      font-size: 2em
      padding: .15em .25em

      &:global(.instagram)
        color: $fc-color-dark-brown

        &:hover,
        &:active
          color: #CF2872

      &:global(.facebook)
        color: $fc-color-dark-brown
        font-size: 2.2em

        &:hover,
        &:active
          color: #3b579d

      &:global(.xing)
        color: $fc-color-dark-brown
        font-size: 1.9em

        &:hover,
        &:active
          color: #025a61

@media all and (min-width: 992px)
  .externalSocialMedia
    justify-content: flex-end
    margin-top: 1.6em

.externalYourXpert
  margin-bottom: 2em
  text-align: center

  &:hover
    filter: none

@media all and (min-width: 768px)
  .externalYourXpert
    filter: grayscale(100%)
    margin-left: .75em
    text-align: left

@media all and (min-width: 992px)
  .externalYourXpert
    margin-left: 0
    margin-right: .75em
    text-align: right
