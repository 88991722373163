@import styles/_variables

.mainUl
  list-style: none
  margin: 0
  padding: 0

.dd
  ul
    list-style: none
    margin: 0
    padding: 0

// ---------------------------------------------
// Header implementation
// ---------------------------------------------

.mainUlHeader
  background-color: #fff
  display: none
  padding-top: 20px

@media all and (min-width: 768px)
  .mainUlHeader
    display: flex

@media all and (min-width: 1201px)
  .mainUlHeader
    margin-left: 178px

.mainUlHeader
  align-items: center
  flex-direction: row

  li
    text-align: left
    margin: 0px 30px 5px 0px

  a
    background-color: transparent
    border-bottom: 3px solid #fff
    color: #333
    display: block
    font-family: $font-primary
    font-size: .875em
    padding: 5px 0px
    text-decoration: none
    text-transform: capitalize

    &:hover
      border-bottom-color: $fc-color-dark-brown

.ddHeader
  display: none

@media all and (min-width: 768px)
  .ddHeader
    display: block

.ddHeader
  width: auto
  margin: 20px -10px 0 -10px
  text-align: center
  position: relative

  & > button
    align-items: center
    background-color: transparent
    border: 0
    color: #6f6f6f
    display: flex
    flex-direction: row
    font-size: .875em
    justify-content: center
    padding: .5em .1em

    & i
      margin-left: .5em

  ul
    background-color: #fff
    border: thin solid $fc-color-light-salmon
    border-radius: 5px
    box-shadow: 0px 4px 8px rgba(0,0,0, 0.3)
    margin-top: -.2em
    opacity: 0
    padding-bottom: 12px
    position: absolute
    right: -10px
    top: 100%
    transition: opacity .3s ease
    visibility: hidden
    z-index: 1000

    a
      color: #6f6f6f
      display: block
      font-family: $font-secondary
      font-size: 1.1em
      padding: .35em 2em
      text-decoration: none
      text-transform: uppercase
      white-space: nowrap

      &:hover
        background-color: $fc-color-light-salmon
        color: #fff

    :global(.separator)
      color: #aaa
      font-size: .75em
      font-family: $font-primary
      font-weight: 300
      margin: 0
      padding: 5px 10px
      text-align: left

  &:hover
    ul
      opacity: 1
      visibility: visible

// ---------------------------------------------
// Drawer implementation
// ---------------------------------------------

.mainUlDrawer,
.ddDrawer
  & :global(a)
    display: block
    font-size: 1.1rem
    padding: .75rem 1rem

.mainUlDrawer
  margin-top: 8px

.ddDrawer
  margin-bottom: 8px

  & > a
    display: none

  & :global(.separator)
    color: #999
    font-size: .75em
    margin: 16px 16px 8px 16px
